import Vue from 'vue';
import Vuex from 'vuex';

import auth from '@/store/modules/auth.module';
import tasks from '@/store/modules/task.module';
import vehicles from '@/store/modules/vehicles.module';
import departments from '@/store/modules/department.module';
import status from '@/store/modules/status.module';
import planning from '@/store/modules/planning.module';
import taskimport from '@/store/modules/taskimport.module';
import jobs from '@/store/modules/job.module';
import drivinglists from '@/store/modules/drivinglist.module';
import transitionperiod from '@/store/modules/transitionperiod.module';
import changelists from '@/store/modules/changelist.module';
import frequencies from '@/store/modules/frequency.module';
import route from '@/store/modules/route.module';
import shared from '@/store/modules/shared.module';
import datageneration from '@/store/modules/datageneration.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    tasks,
    vehicles,
    departments,
    status,
    planning,
    import: taskimport,
    jobs,
    drivinglists,
    transitionperiod,
    changelists,
    frequencies,
    route,
    shared,
    datageneration,
  },
});
