export const LOGIN = 'login';
export const FIRST_LOGIN = 'firstLogin';
export const FORCE_LOGOUT = 'forceLogout';
export const REFRESH_TOKEN = 'refreshToken';
export const CHECK_AUTH = 'checkAuth';
export const BLACKLIST = 'blacklist';
export const RECOVER_PASSWORD = 'recoverPassword';
export const RESET_PASSWORD = 'resetPassword';
export const FORBIDDEN_REQUEST = 'forbiddenRequest';
export const FORBIDDEN_EXTERNAL_ACCCESS_REQUEST = 'forbiddenExternalAccessRequest';

export const UPDATE_TASK = 'updateTask';
export const DELETE_TASK = 'deleteTask';
export const SUBMIT_BULK_UPDATE = 'submitBulkUpdate';
export const DELETE_TASKS_BULK = 'deleteTasksBulk';
export const EXPORT_TASKS = 'exportTasks';
export const DOWNLOAD_TASKS = 'downloadTasks';

export const SUBMIT_FREQUENCY_MAPPINGS = 'submitFrequencyMappings';
export const SUBMIT_PROPERTYZONE_MAPPINGS = 'submitPropertyZoneMappings';
export const GEOCODE_ALL_TASKS = 'geocodeAllTasks';
export const GET_GEOCODE_AUDIT_COUNT = 'getGeocodeAuditCount';
export const ZONECODE_ALL_TASKS = 'zonecodeAllTasks';

export const GET_TASKS_NOT_GEOCODED = 'getTasksNotGeocoded';
export const SUBMIT_MANUALLY_GEOCODED_TASKS = 'submitManuallyGeocodedTasks';

// shared
export const SHARED_ALL_VEHICLES = 'sharedAllVehicles';
export const SHARED_GET_MOST_IRREGULAR_FREQUENCY = 'sharedGetMostIrregularFrequency';
export const SHARED_GET_FREQUENCY_OPTIONS = 'sharedGetFrequencyOptions';

export const ALL_VEHICLES = 'allVehicles';
export const EDIT_VEHICLE = 'editVehicle';
export const CREATE_VEHICLE = 'createVehicle';
export const DELETE_VEHICLE = 'deleteVehicle';

// Groups
export const ALL_GROUPS = 'allGroups';
export const ASSIGN_GROUP = 'assignGroup';
export const RENAME_GROUP = 'renameGroup';
export const CREATE_GROUP = 'createGroup';
export const MOVE_GROUP = 'moveGroup';
export const DELETE_GROUP = 'deleteGroup';

// Workspaces / Departments
export const ALL_DEPARTMENTS = 'allDepartments';
export const CREATE_DEPARTMENT = 'createDepartment';
export const RENAME_DEPARTMENT = 'renameDepartment';
export const DELETE_DEPARTMENT = 'deleteDepartment';
export const COPY_DEPARTMENT = 'copyDepartment';
export const CREATE_SHARED_WORKSPACE_LINK = 'createSharedWorkspaceLink';
export const DELETE_SHARED_WORKSPACE_LINK = 'deleteSharedLink';
export const GET_SHARED_WORKSPACE_LINK = 'getSharedWorkspaceLink';

export const DELETE_EXPERIMENT = 'deleteExperiment';
export const CREATE_EXPERIMENT = 'createExperiment';

export const GET_FILTERED_INSTANCES = 'getFilteredInstances';
export const GET_ROUTE = 'getRoute';

export const BUILD_EXISTING_ROUTES = 'buildExistingRoutes';
export const BUILD_ROUTES_FROM_SCRATCH = 'buildRoutesFromScratch';

export const BUILD_ROUTES_FROM_SCRATCH_AUTOMATIC = 'buildRoutesFromScratchAutomatic';

// task import module
export const BUILD_MERGE_PLAN = 'buildMergePlan';
export const UPLOAD_FOR_MERGE_PLAN = 'uploadForMergePlan';
export const SUBMIT_MERGE_PLAN_CONFIRM = 'submitMergePlanConfirm';
export const IMPORT_TASKS = 'importTask';
export const IMPORT_EXTRA_TASKS = 'importExtraTasks';

export const GET_IMPORT_AUDIT = 'getImportAudit';
export const GET_CSV_HEADERS = 'getCsvHeaders';
export const GET_NEW_FREQUENCIES_TO_MAP = 'getNewFrequenciesToMap';

// status
export const CHECK_TASK_STATUS = 'checkTaskStatus';
export const CHECK_GEOCODE_STATUS = 'checkGeocodeStatus';
export const CHECK_ZONECODE_STATUS = 'checkZonecodeStatus';
export const CHECK_MATERIEL_STATUS = 'checkMaterielStatus';

export const CHECK_FREQUENCY_CODED_STATUS = 'checkFrequencyCodedStatus';
export const CHECK_PLANNED_STATUS = 'checkPlannedstatus';

export const CHECK_STATUS_ALL = 'checkStatusAll';

export const CHECK_RUNNING_JOBS = 'checkRunningJobs';

export const GENERATE_DRIVING_LISTS = 'generateDrivingLists';
export const UPDATE_DURATIONS_AND_DISTANCES = 'updateDurationsAndDistances';
export const GET_DRIVING_LISTS = 'getDrivingLists';
export const GET_DRIVING_LIST_CANDIDATES = 'getDrivingListCandidates';
export const GET_ZIPPED_DRIVING_LIST_INFO = 'getZippedDrivingListInfo';

export const GENERATE_TRANSITION_WORKSPACE = 'generateTransitionWorkspace';
export const GET_TRANSITION_PERIOD_DETAILS = 'getTransitionPeriodDetails';

export const UPGRADE_EXPERIMENT_TO_MAIN = 'upgradeExperimentToMain';

export const GET_CHANGE_LISTS = 'getChangeLists';
export const GENERATE_CHANGE_LIST = 'generateChangeList';

// frequency
export const GET_MOST_IRREGULAR_FREQUENCY = 'getMostIrregularFrequency';
export const GET_FREQUENCY_OPTIONS = 'getFrequencyOptions';

export const UPDATE_SORTED_STOPS = 'updateSortedStops';
export const UPDATE_SELECTED_STOPS = 'updateSelectedStops';

// data generation
export const DOWNLOAD_ADMINISTRATIVE_AREAS = 'downloadAdministrativeAreas';
export const GET_ADMINISTRATIVE_AREAS = 'getAdministrativeAreas';
export const EXPORT_ADMINISTRATIVE_AREAS = 'exportAdministrativeAreas';
