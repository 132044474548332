import Interceptors from '@/common/interceptors';

import axios from 'axios';

const ApiService = {
  instance: null,

  init() {
    this.instance = axios.create({
      withCredentials: true,
      baseURL: process.env.VUE_APP_APIURL,
      headers: {
        'Accept-Language': 'da-DK',
      },
    });

    // eslint-disable-next-line no-new
    new Interceptors(this.instance);
  },

  querySingle(resource, param) {
    if (!param) {
      throw new Error('param cannot be null');
    }
    return this.instance.get(`${resource}/${param}`);
  },

  get(resource, params) {
    if (params) {
      return this.instance.get(resource, params);
    }

    return this.instance.get(resource);
  },

  post(resource, body) {
    return this.instance.post(`${resource}`, body);
  },

  patch(resource, id, payload) {
    return this.instance.patch(`${resource}/${id}/`, payload);
  },

  patchWithNoId(resource, payload) {
    return this.instance.patch(resource, payload);
  },

  put(resource, id, params) {
  if (!id) {
    // allow resources where the id is in the middle for example tasks/{id}/upgrades
    return this.instance.put(resource, params);
  }
  return this.instance.put(`${resource}/${id}/`, params);
},

  deleteInCurrentWorkspace(resource) {
    return this.instance.delete(`${resource}`);
  },

  delete(resource) {
    return this.instance.delete(resource)
      .then((response) => response)
      .catch((error) => {
        throw error;
      });
  },

  deleteWithParam(resourceWithParam) {
    return this.instance.delete(resourceWithParam);
  },

  getFile(resource) {
    return this.instance.get(resource, { responseType: 'blob' });
  },
};

export default ApiService;
